import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['numOfCustomers']
  connect() {

  }

  updateNumOfCustomers(event) {
    const selectedValue = event.target.value;

    if (selectedValue === 'Web App') {
      this.numOfCustomersTarget.classList.add("hidden");
    } else {
      this.numOfCustomersTarget.classList.remove("hidden");
    }
  }
}
